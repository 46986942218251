































































































































import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import Sidebar from '@/components/left-sidebar.vue'; // @ is an alias to /src
import RSidebar from '@/components/right-sidebar.vue'; // @ is an alias to /src
import VendorService from "../../services/vendor-service";

@Component({
  components: {
    Header,
    Sidebar,
    RSidebar
  },
})
export default class Vendor extends Vue {
  public filter = null;
  private title: string = "";
  public create_obj: any = {}

  public selected_items = [];
  public currentPage = 1;
  public fields = [
  {
      key: 'title',
      sortable: false
    },
    {
      key: 'email',
      sortable: false
    },
    {
      key: 'contact_num',
      sortable: false,

    },
    {
      key: 'type',
      sortable: false,
    }

  ];

  public items = [];
  public ddl_items:any = []
  public import_response = '';
  public import_file: any = null;
  
  get rows() {
    return this.selected_items.length;
  }

  retrieve() {
    VendorService.get_list()
      .then((response) => {
        this.items = response.data;
        this.ddl_items = response.data;
        this.selected_items = this.items;
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  refreshList() {
    this.retrieve();
  }

  submit_import() {
  }
  create() {
    this.create_obj['user'] = 2;
        VendorService.create_list(this.create_obj).then((response) => {
          this.create_obj = {};
            alert('Successfully Created')
            this.retrieve()
        }).catch((e) => {
        })
    }

  // searchTitle() {
  //   VendorService.getByTitle(this.title)
  //     .then((response) => {
  //       this.data = response.data;
  //       console.log(response.data);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }


  onRowClicked(item: any, index: any, event: any) {
    // this.$router.resolve({ path: `/create-list` });
  }
  
  mounted() {
    this.retrieve();
  }
}
