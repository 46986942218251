import http from "../common-http";

class VendorService {

  // project-item
  get_list() {return http.get("/vendor/list/");}

  get_list_by_id(id: any) {return http.get(`/vendor/list/${id}/`);}
  
  create_list(data: any) {return http.post("/vendor/list/", data);}

  getByTitle(title: any) {
    return http.get(`/vendor/get-vendor/?title=${title}`);
  }  


}

export default new VendorService();